.block {
  position: relative;
  transform: translateX(-50%);
  margin-left: 50%;
  height: 220px;
  width: 100%;
  text-align: center;
}

.coin {
  position: relative;
  transform: scale(1);
  transition: all 0.5s cubic-bezier(0.22, 1, 0.36, 1);
}

.coinAnimation {
  transform: scale(1.07);
}
.coinIcon {
  transition: transform 0.4s ease 0s;
}
.coinAnimationX2 {
  transform: scale(0);
}

.coinAnimation {
  transform: scale(1.07);
}

.logo {
  opacity: 0;
  transition: all 0.5s;
}
.logoActive {
  opacity: 1;
  transition: all 0.5s;
}

.amount {
  user-select: none;
  position: absolute;
  font-weight: 600;
  font-size: 25px;
  top: 48%;
  left: 48%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
  transition:
    opacity 0.3s,
    scale 0.5s cubic-bezier(0.22, 1, 0.36, 1);
}
.amountActive {
  opacity: 1;
  user-select: none;
  position: absolute;
  font-weight: 600;
  font-size: 25px;
  top: 48%;
  left: 48%;
  transform: translate(-50%, -50%) scale(1.07);
  transition:
    opacity 0.3s,
    scale 0.5s cubic-bezier(0.22, 1, 0.36, 1);
}

.amount_red {
  color: #be2e00;
}
.amount_gold {
  color: gold;
}
