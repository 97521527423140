.cover{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 430px;
  z-index: -1;
  opacity: 0.5;
}
.title{
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.body{
  display: flex;
  flex-direction: column;
  gap: 10px;
  &>*{
    max-width: 100%;
  }
}
