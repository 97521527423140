.block{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.column{

}
.title{
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin-bottom: 1px;
}
.subtitle{
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
}
.actions{
  display: flex;
  gap: 10px;
}
.edit{

}
.delete{

}

.complete{
  color: #63EFA8;
}