.block{

}
.btn{
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #6d7286;
  border: 2px solid #282b3d;
  border-radius: 20px;
  width: 117px;
  height: 41px;
  background: #1b1c2e;
}
.wrapper{
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
}
.modal{
  position: absolute;
  top: 0;
  left: 50%;
  width: 100dvw;
  max-width: 430px;
  height: 61px;
  border: 2px solid #282b3d;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1b1c2e;
  padding: 0 10px;
  pointer-events: none;
  transform: translate(-50%, -110%);
  transition: all 0.5s ease-in-out;
}
.column{
  display: flex;
  align-items: center;
  gap: 10px;
}
.wallet{
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}
.walletAdd{
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  border: 1px solid #4987f4;
  border-radius: 10px;
  width: 187px;
  height: 31px;
  background: #2c6dfb;
}
.disconnect{
  font-weight: 400;
  font-size: 12px;
  color: #2c6dfb;
}
.cross{

}

.wrapperActive{
  opacity: 1;
  pointer-events: all;
  &>.modal{
    pointer-events: all;
    transform: translate(-50%, 0);
  }
}