.block{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  z-index: 10;
  position: relative;
  &>*>*{
    display: flex;
    justify-content: space-between;
    align-items: center
  }
}
.ratio{
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: red;
  &>*{
    border-radius: 50%;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    display: flex;
    margin: 1px;
    background: #7040EF;
    align-items: center;
    justify-content: center;
  }
}


.less{
  height: 54px;
  border-radius: 50px 10px 10px 50px;
  flex: 1 1 50%;
  background: red;
  background: linear-gradient(90deg, #5d27ec 0%, #ac8dff 100%);
  &>*{
    padding: 0 17px 0 9px;
    background: linear-gradient(63deg, #5b25eb 0%, #8c62fe 100%);
    border-radius: 50px 10px 10px 50px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px;
    &>.ratio{
      background: linear-gradient(90deg, #ac8dff 0%, #5d27ec 100%);
    }
  }
  &:disabled{
    pointer-events: none;
    cursor: auto;
  }
}
.more{
  height: 54px;
  border-radius: 10px 50px 50px 10px;
  flex: 1 1 50%;
  background: red;
  background: linear-gradient(90deg, #ac8dff 0%, #5d27ec 100%);
  pointer-events: all;
  cursor: pointer;
  &>*{
    padding: 0 9px 0 17px;
    border-radius: 10px 50px 50px 10px;
    background: linear-gradient(297deg, #5b25eb 0%, #8c62fe 100%);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px;
    &>.ratio{
      background: linear-gradient(90deg, #5d27ec 0%, #ac8dff 100%);
    }
  }
  &:disabled{
    pointer-events: none;
    cursor: auto;
  }
}

.title{
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}


