.block{
  margin-top: 20px;
}

.title{
  font-weight: 400;
  font-size: 16px;
  color: #5a6076;
  margin-bottom: 15px;
}
.settings{
  margin-top: 10px;
  backdrop-filter: blur(70px);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 20px 20px;
  margin-bottom: 25px;
}
.mailing{
  margin-top: 10px;
  backdrop-filter: blur(70px);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 20px 20px;
  margin-bottom: 25px;
}

.input{
  width: 100%;
  height: 40px;
  background: #171926;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  outline: none;
  font-size: 14px;
  color: #fff;
  flex: 1 1 100%;
  padding: 7px 15px;
  margin-bottom: 10px;
  &::placeholder{
    color: #3f414e;
  }
}

.textarea{
  width: 100%;
  resize: none;
  background: #171926;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  outline: none;
  font-size: 14px;
  color: #fff;
  padding: 7px 15px;
  margin-bottom: 10px;
  &::placeholder{
    color: #3f414e;
  }
}

.mailingUpload{
  width: 250px;
  height: 40px;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  &>div{
    cursor: pointer;
    overflow: hidden;
    text-wrap: nowrap;
    max-width: 250px;
    height: 40px;
    color: #fff;
    display: flex;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    background: #171926;
    border-radius: 10px;
  }
  &>input{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.mailingBtn{
  width: 100%;
  height: 40px;
  color: #fff;
  margin-top: 10px;
  background: #0A4AE6;
  border-radius: 10px;
}