.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 60px;
  left: 0;
  z-index: 100;
  transform: translate(0px, 100%);
  transition: transform 0.3s ease 0s;
}
.body {
  width: 100%;
  height: 100%;
  background: #1b1c2e;
  border: 2px solid #282b3d;
  border-radius: 20px;
}
.gameActive {
  transform: translate(0px, 0px);
}
.fallingCoinsBlock {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
