.row {
  display: flex;
  justify-content: space-between;
  gap: 7px;
  margin-bottom: 10px;
}
.language {
  cursor: pointer;
  position: relative;
  flex: 0 1 90px;
  background: #1b1c2e;
  border: 2px solid #282b3d;
  border-radius: 20px;
  font-weight: 400;
  font-size: 18px;
  color: #6d7286;
  height: 41px;
  padding: 9px 15px 11px 15px;
  @media (max-width: 380px) {
    font-size: 16px;
    padding: 10px 10px 11px 10px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
    border-radius: 100%;
    border: 1px solid #282b3d;
    transition: all 0.5s ease 0s;
    @media (max-width: 370px) {
      width: 12px;
      height: 12px;
    }
  }
}

.languageRight {
  display: flex;
  justify-content: end;
  &:before {
    background: #2c6dfb;
    left: 5px;
  }
}
.languageLeft {
  display: flex;
  justify-content: start;
  &:before {
    background: #5e29ed;
    right: 5px;
  }
}
