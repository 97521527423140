 .wrapper{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100dvw;
  height: 100dvh;
  max-width: 430px;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
}
.wrapperActive{
  opacity: 1;
  pointer-events: all;
}
.body{
  position: absolute;
  bottom: 60px;
  left: 20px;
  width: calc(100% - 40px);
  max-height: 85dvh;
  overflow-y: scroll;
  background: #171926;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 20px;
}
.title{
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
}
.subtitle{
  font-weight: 400;
  font-size: 12px;
  color: #3f414e;
  margin-bottom: 10px;
}

.selectType{
  border-radius: 10px;
  background: #292b38;
  padding: 3px;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}
.selectType_btn{
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #fff;
  transition: all 0.5s;
  background: #292b38;
  flex: 1 1 34%;
  height: 29px;
  border-radius: 7px;
}
.selectType_btnActive{
  background: #171926;
}

.location{
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #fff;
  transition: all 0.5s;
  background: #292b38;
  flex: 1 1 34%;
  height: 29px;
  border-radius: 7px;
}

.locationActive{
  background: #171926;
}

.input{
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  backdrop-filter: blur(70px);
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: #ffffff;
  padding: 17px 20px;
  background: transparent;
  outline: none;
  &::placeholder{
    color: #3f414e;
  }
}

.btn{
  backdrop-filter: blur(70px);
  background: #7bbbff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #0d4de6;
  width: 100%;
  height: 50px;

}

.name{
  font-weight: 400;
  font-size: 12px;
  color: #3f414e;
  margin-bottom: 5px;
}