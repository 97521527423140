.layout {
  position: relative;
}
.cover {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100dvw;
  max-width: 430px;
  height: auto;
  pointer-events: none;
  z-index: -1;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.language {
  width: 90px;
  position: fixed;
  top: 10px;
  left: 10px;
  cursor: pointer;
  background: #1b1c2e;
  border: 2px solid #282b3d;
  border-radius: 20px;
  font-weight: 400;
  font-size: 18px;
  color: #6d7286;
  height: 30px;
  padding: 4px 15px 11px 15px;
  z-index: 10000;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
    border-radius: 100%;
    border: 1px solid #282b3d;
    transition: all 0.5s ease 0s;
    @media (max-width: 370px) {
      width: 15px;
      height: 15px;
    }
  }
  @media (max-width: 370px) {
    font-size: 16px;
    height: 25px;
    width: 80px;
    padding-top: 2px;
  }
}

.languageRight {
  display: flex;
  justify-content: end;
  &:before {
    background: #2c6dfb;
    left: 7px;
  }
}
.languageLeft {
  display: flex;
  justify-content: start;
  &:before {
    background: #5e29ed;
    right: 7px;
  }
}

.step {
  display: flex;
  align-items: center;
}

.imageWrapper {
  height: 326px;
  max-width: 100%;
  position: relative;
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.desc {
  max-height: 150px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.title {
  width: 100%;
  font-weight: 500;
  font-size: 26px;
  text-align: center;
  color: #fff;
  margin-bottom: 14px;
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #bcc0d9;
  width: 100%;
}

.row {
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.next {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #fff;
  width: 200px;
  height: 54px;
  background: #0a4ae6;
  border-radius: 50px;

  border: 1px solid #0a4ae6;
  transition: all 0.5s ease 0s;
  @media (hover: hover) {
    &:hover {
      background: #fff;
      color: #0a4ae6;
    }
  }
}

.dots {
  display: flex;
  gap: 5px;
  align-items: center;
  & span {
    width: 6px;
    height: 6px;
    background-color: #5d5f6c;
    border-radius: 60px;
    transition: all 0.3s ease 0s;
    &._active {
      background-color: #fff;
      width: 33px;
    }
  }
}

.bonus {
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  color: #2a67ff;
}
