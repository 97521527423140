
.cover{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 430px;
  z-index: -1;
  opacity: 0.5;
}

.title{
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #fff;
  width: 100%;
  margin-bottom: 29px;
}

.body{
  display: flex;
  flex-direction: column;
  padding-bottom: 110px;
  gap: 10px;
}

.item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 19px 20px 19px;
  background: #2b2d3a;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
}

.item__name{
  font-weight: 400;
  font-size: 18px;
  color: #5a6076;
  max-width: 200px;
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accessCopy{
  position: absolute;
  display: none ;
  top: 0;
  left: 0;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.item__amount{
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  color: #fff;
}

.addFriend{
  position: fixed;
  bottom: 114px;
  left: 50%;
  transform: translateX(-50%);
  height: 54px;
  width: 322px;
  border-radius: 50px;
  background: linear-gradient(90deg, #0a4ae6 0%, #3272d7 100%)
}
.addFriend__text{
  background: linear-gradient(63deg, #3272d7 0%, #0a4ae6 100%);
  border-radius: 50px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #fff;
}