.block{
  padding: 20px;
}
.cover{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
}
.title{
  font-weight: 500;
  font-size: 26px;
  color: #3d3f4c;
  margin-bottom: 20px;
}
.navbar{
  display: flex;
  border-radius: 10px;
  height: 35px;
  background: #292b38;
  width: 100%;
  padding: 3px;
}

.link{
  flex: 1 1 50%;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
}

.linkActive{
  background: #171926;
}

.body{

}