.item{
  background: #2b2d3a;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 19px 14px;
  gap: 15px;
}


.item__image{

}
.item__column{
  flex: 1 1 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
  flex-direction: column;
}
.item__title{
  font-weight: 400;
  font-size: 18px;
  color: #fff;
}
.item__subtitle{
  font-weight: 400;
  font-size: 12px;
  color: #5a6076;
}
.item__bonus{
  font-weight: 500;
  font-size: 18px;
  color: #2c6dfb;
  text-wrap: nowrap;
}

.itemCompleted{
  pointer-events: none;
  &>*>.item__title{
    color: #494B58;
  }
  &>*>.item__subtitle{
    color: #494B58;
  }
  &>.item__bonus  {
    color: #494B58;
  }
  &>*.item__image{
    filter: grayscale(100%);
  }
}

.check{
  text-shadow: 0 0 20px 0 rgba(44, 109, 251, 0.5);
  font-weight: 500;
  font-size: 18px;
  color: #2c6dfb;
}