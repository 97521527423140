.block{
  backdrop-filter: blur(70px);
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  margin-top: 20px;
  &>*:first-child{
    margin-bottom: 15px;
    &>*>.circle{
      background: #0d4de6 !important;
    }
  }


}
.row{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  &>*:first-child{
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 0 0 133px;
  }
  &>*:nth-child(2){
    flex: 1 1 100%;
  }
}
.circle{
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #8c62fe;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}
.name{
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.progressBar{
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.3);
  position: relative;
  border-radius: 10px;
  &:after{
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    left: 0;
    background: #0d4de6;
    border-radius: 10px;
  }
}
.value{
  font-size: 14px;
  text-align: right;
  color: #fff;
}

.title{
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
}
.items>*:not(:last-child){
  margin-bottom: 10px;
}
.item{
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #171926;
  border-radius: 10px;
}
.itemTitle{
  font-size: 16px;
  color: #5a6076;
}
.itemStatus{
  display: flex;
  align-items: center;
  gap: 5px;
}
.itemCircle{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}