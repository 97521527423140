input,
textarea,
button,
select,
a,
* {
  -webkit-tap-highlight-color: transparent;
}
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Ubuntu;
}

html,
body {
  height: 100%;
  min-width: 350px;
  background-color: #171926;
  color: #fff;
}

body {
  line-height: 1;
  font-size: 1.125rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font-size: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

a {
  color: inherit;
}

a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

body {
  height: 100dvh;
  scroll-behavior: smooth;
  color: #000;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: #fff #575757;
}
.wrapper {
  height: 100dvh;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.background {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 100%;
  max-width: 430px;
  height: auto;
}

[class*="_container"] {
  //height: 100dvh;
  max-width: 430px;
  margin: 0 auto;
  width: 100dvw;
}

*::-webkit-scrollbar {
  width: 0;
}

/*Убрать полосу прокрутки для всей страницы*/
::-webkit-scrollbar {
  width: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  background: #171926;
}
body::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 50px;
}
