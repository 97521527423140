.wrapper{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  width: 100dvw;
  height: 100dvh;
  max-width: 430px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
}
.wrapperActive{
  opacity: 1;
  transform: translateX(-50%);
  pointer-events: all;
}
.body{
  position: absolute;
  left: 10px;
  width: calc(100% - 20px);
  bottom: 103px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  background: #171926;
  padding: 20px;
}
.title{
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin-bottom: 19px;
}
.cross{

}
.info{
  margin-bottom: 17px;
  &>*:not(:last-child){
    margin-bottom: 14px;
  }
}
.row{
  display: flex;
  gap: 10px;
  align-items: center;
}
.name{
  font-weight: 400;
  font-size: 14px;
  color: #5a6076;
  width: 104px;
}
.value{
  font-weight: 400;
  font-size: 14px;
  color: #fff;

}
.actions{
  display: flex;
  align-items: center;
  gap: 5px;
}
.btn{
  flex: 0 1 25%;
  border: 1px solid #5a6076;
  border-radius: 10px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
}
.btnBlue{
  color: #0d4de6;
}
.btnYellow{
  color: rgba(255, 170, 11, 0.98)
}
.btnGreen{
  color: #58f3a6;
}
.btnRed{
  color: #fe9ea2;
}
.btnBlueActive{
  background: #8fcfff;
}
.btnYellowActive{
  background: #ecdab5;
}
