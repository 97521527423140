.back{
  background: #2b2d3a;
  padding: 15px 25px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}
.backBtn{
  border-radius: 12px;
  width: 40px;
  height: 40px;
  background: #171926;
  display: flex;
  align-items: center;
  justify-content: center;

}
.backTitle{
  font-size: 18px;
  color: #fff;
}
.form{
  padding: 0 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.formTitle{
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
}
.formSubTitle{
  font-size: 16px;
  text-align: center;
  color: #5a6076;
  margin-bottom: 15px;
  max-width: 230px;
}
.formInput{
  border: 2px solid #2c303e;
  border-radius: 10px;
  font-size: 18px;
  color: #fff;
  outline: none;
  padding: 16px;
  width: 100%;
  background: transparent;
  margin-bottom: 10px;
  &::placeholder{
    color: #6d7286;
  }
}
.formBtn{
  height: 54px;
  width: 100%;
  border-radius: 50px;
  background: linear-gradient(90deg, #0a4ae6 0%, #3272d7 100%);
  margin-bottom: 15px;
}
.formBtn__content{
  background: linear-gradient(63deg, #3272d7 0%, #0a4ae6 100%);
  border-radius: 50px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #fff;
  padding: 17px 0 19px 0;
}
.formInfo{
  font-size: 16px;
  text-align: center;
  color: #5a6076;
}
.withdrawals{
  background: #1b1c2e;
  border: 2px solid #282b3d;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 20px;
}
.withdrawalItem{
  display: flex;
  justify-content: space-between;
  padding: 19px 20px 20px 20px;
}
.withdrawalItemTitle{
  font-size: 18px;
  color: #fff;
}
.withdrawalItemStatus{
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffaa0b;
}
.history{
  background: #1b1c2e;
  border: 2px solid #282b3d;
  border-radius: 20px;
  width: 100%;
  padding: 20px;
  padding-bottom: 5px;
}
.historyTitle{
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
}
.historyItems>*:not(:last-child){
  border-bottom: 2px solid #282b3d;
}
.historyItem{
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
}
.historyItemInfo{

}
.historyItemType{
  font-size: 16px;
  color: #5a6076;
  margin-bottom: 5px;
}
.historyItemAmount{
  font-size: 16px;
  color: #969cb2;
}
.historyItemColumn{

}
.historyItemStatus{
  font-size: 16px;
  text-align: right;
  color: #58f3a6;
  margin-bottom: 5px;
}
.historyItemStatusError{
  color: #fe9ea2;
}
.historyItemUsername{
  font-size: 16px;
  text-align: right;
  color: #969cb2;
}
