.gif{
  background: #1b1c2e;
  border: 2px solid #282b3d;
  border-radius: 20px;
  width: 152px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px 0 15px;
  pointer-events: all;
  cursor: pointer;
  &>*>*{
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #6d7286;
  }
  &_disabled{
    pointer-events: none;
    cursor: auto;
  }
}

.text{
  color: #2c6dfb !important;
  transform: translateY(-2px);
}

.image{
  filter: grayscale(100%);
  transform: translateY(-5px);
}
.imageActive{
  filter: none;
  animation: gift 2s infinite linear;
}

@keyframes gift {
  0%{
    transform: translateY(-5px);
  }
  65% {
    transform: translateY(-5px) rotate(0deg);
  }
  70% {
    transform: translateY(-5px) rotate(20deg);
  }
  75% {
    transform: translateY(-5px) rotate(-20deg);
  }
  80% {
    transform: translateY(-5px) rotate(20deg);
  }
  85% {
    transform: translateY(-5px) rotate(-20deg);
  }
  90% {
    transform: translateY(-5px) rotate(20deg);
  }
  95% {
    transform: translateY(-5px) rotate(-20deg);
  }

}