.wrapper{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  width: 100dvw;
  height: 100dvh;
  max-width: 430px;
  opacity: 0;
  z-index: 10000;
  pointer-events: none;
  transition: all 0.5s;
}
.wrapperActive{
  opacity: 1;
  transform: translateX(-50%);
  pointer-events: all;
}
.body{
  position: absolute;
  left: 10px;
  width: calc(100% - 20px);
  bottom: 103px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  background: #171926;
  padding: 20px;
}
.title{
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-bottom: 15px;
}
.row{
  display: flex;
  align-items: center;
  gap: 10px
}
.confirm{
  background: linear-gradient(63deg, #3272d7 0%, #0a4ae6 100%);
  border-radius: 400px;
  font-size: 18px;
  text-align: center;
  color: #fff;
  width: 100px;
  height: 40px;
}
.notconfirm{
  border-radius: 400px;
  background: #202538;
  text-align: center;
  color: #fff;
  width: 100px;
  height: 40px;
}