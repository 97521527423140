.coin {
  cursor: pointer;
  position: absolute;
  top: -100px;
  user-select: none;
}
.falling {
  transform: translate(0px, 2500px);
}
.bonusText {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  color: #fff;
  animation: plusCoinTextAnimation 2s ease-out 1;
  opacity: 0;
  user-select: none;
}

@keyframes plusCoinTextAnimation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: translate(0px, -100px);
    opacity: 0;
  }
}
