.wrapper{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  width: 100dvw;
  height: 100dvh;
  max-width: 430px;
  opacity: 0;
  z-index: 100;
  pointer-events: none;
  transition: all 0.5s;
}
.wrapperActive{
  opacity: 1;
  transform: translateX(-50%);
  pointer-events: all;
}
.body{
  position: absolute;
  left: 10px;
  width: calc(100% - 20px);
  bottom: 103px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  background: #171926;
  padding: 20px;
}

.title{
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
}
.btn{
  backdrop-filter: blur(70px);
  background: #7bbbff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #0d4de6;
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
}
.btnDelete{
  backdrop-filter: blur(70px);
  background: #FE9EA2;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #0d4de6;
  width: 100%;
  height: 50px;
}
.input{
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  backdrop-filter: blur(70px);
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: #ffffff;
  padding: 17px 20px;
  background: transparent;
  outline: none;
  &::placeholder{
    color: #3f414e;
  }
}