.block{
  margin-top: 20px;
}
.addTask{
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 8px 8px 20px;
  backdrop-filter: blur(70px);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
}

.title{
  font-weight: 400;
  font-size: 16px;
  color: #5a6076;
  margin-bottom: 15px;
}

.addTask__btn{
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 0;
  margin: 0;
  background: transparent;
}
.addTask__title{
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.tasks{
  margin-top: 10px;
  backdrop-filter: blur(70px);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 0 20px;
  margin-bottom: 25px;
  &>*:not(:last-child){
    border-bottom: 1px solid #d9d9d9;
  }
}

.withdrawal{
  backdrop-filter: blur(70px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 15px 20px;
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.05);
}
.withdrawalRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.withdrawalTitle{
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}
.withdrawalTumb{

}
.withdrawalSubTitle{
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
}
.withdrawalInput{
  backdrop-filter: blur(70px);
  background: #171926;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  display: flex;
  padding: 5px;
  align-items: center;
  &>input{
    background: transparent;
    outline: none;
    font-size: 14px;
    color: #fff;
    flex: 1 1 100%;
    padding: 7px 15px;
    &::placeholder{
      color: #3f414e;
    }
  }
}
.withdrawalBtn{
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  flex: 0 0 30%;
  border-radius: 100%;
  background: #0d4de6;
}



.switch {
  display: inline-block;
  height: 30px;
  position: relative;
  width: 84px;
}

.switch input {
  display:none;
}

.slider {
  background-color: #643AD6;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.5s ease 0s;
}

.slider:before {
  background-color: #8C62FE;
  bottom: 2px;
  content: "";
  height: 26px;
  left: 2px;
  position: absolute;
  transition: all 0.5s ease 0s;
  width: 26px;
}

input:checked + .slider {
  background-color: #0D4DE6;
  transition: all 0.5s ease 0s;
}

input:checked + .slider:before {
  transform: translateX(54px);
  background-color: #67A7FF;
  transition: all 0.5s ease 0s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switchRight{
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: auto;
  right: 15px;
  transform: translateY(-50%);
  z-index: 500;
  pointer-events: none;
}
.switchLeft{
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 15px;
  right: auto;
  transform: translateY(-50%);
  z-index: 500;
  pointer-events: none;
}

.links{
  margin-top: 10px;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  &>*:not(:last-child){
    border-bottom: 1px solid #d9d9d9;
  }
}
.addLink{
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 8px 8px 20px;
  backdrop-filter: blur(70px);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
}
.addLink__btn{
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 0;
  margin: 0;
  background: transparent;
}
.addLink__title{
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #fff;
}

.link{
  padding: 20px 0;
  &>*:first-child{
    margin-bottom: 20px;
  }
}
.link__title{
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-bottom: 5px;
  background: none;
  border: none;
  outline: none;
}
.link__url{
  font-weight: 400;
  font-size: 16px;
  color: #5a6076;
}
.link__counters{
  display: flex;
  justify-content: space-between;
}
.link__counter{
  font-size: 18px;
  color: #fff;
}