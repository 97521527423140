.block {
  display: flex;
  flex-direction: column;
  max-height: 100dvh;
  height: 100dvh;
  overflow: hidden;
  position: relative;
}
.body {
  flex: 1 1 100%;
  z-index: 10;
  overflow: auto;
  padding: 10px 10px;
  margin-bottom: -10px;
  position: relative;
}
