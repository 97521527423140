.block{
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.cover{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 430px;
  height: auto;
  z-index: -1;
  pointer-events: none;
  opacity: 0.5;
}

.body{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image{
  margin-bottom: 37px;
}
.title{
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
}
.subtitle{
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  color: #2a67ff;
  margin-bottom: 30px;
}
.btn{
  width: 230px;
  height: 54px;
  border-radius: 50px;
  background: linear-gradient(90deg, #0a4ae6 0%, #3272d7 100%);
  pointer-events: all;
  cursor: pointer;
}
.btn__content{
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #fff;
  border-right: 50px;
  background: linear-gradient(63deg, #3272d7 0%, #0a4ae6 100%);
  border-radius: 50px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stars{
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 300px;
  &>*{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &>*:nth-child(1){
    transform: translate(-50%, -50%) rotate(90deg);
  }
}


.streak{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  &>*:last-child{
    flex: 0 1 calc(50% - 10px) !important;
    aspect-ratio: auto !important;
  }
}
.streakItem{
  border: 1px solid #3272D7;
  border-radius: 5px;
  flex: 0 1 calc( (100% - 40px) / 4);
  margin: 5px;
  aspect-ratio: 1;
  background: #1b1c2e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 11px 0 12px 0;
  &>*{
    filter: grayscale(100%);
  }
}
.streakItemActive{
  box-shadow: 0 0 20px 0 #fff;
  &>*{
    filter: none;
  }
}
.streakAmount{
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #2a67ff;
}
.row{
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 12px 0 20px;
  align-items: center;

}
.column{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
  align-items: flex-end;
}
.streakItemPrevActive{
  &>*{
    filter: none;
  }
}