.block{
  margin-bottom: 20px;
  z-index: 10;
  position: relative;
}
.scale{
  width: 100%;
  height: 44px;
  position: relative;
  border-radius: 10px;
  border: 2px solid #2c303e;
  background: #1B1C2E;
  &:before{
    position: absolute;
    content: "";
    top: 11px;
    left: 14px;
    height: 23px;
    width: calc(100% - 28px);
    pointer-events: none;
    background: url("../../../../public/image/icons/scale.svg") no-repeat;
    z-index: 0;
  }
}
.toggle{
  position: absolute;
  top: 1px;
  width: 38px;
  height: 38px;
  z-index: 2;
  transform: translateX(0);
  background: linear-gradient(63deg, #5b25eb 0%, #8c62fe 100%);
  border-radius: 7px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  transition: left 1.5s cubic-bezier(0.14, 1.00, 0.34, 1.00);
}

.start{
  opacity: 1;
  position: absolute;
  bottom: 51px;
  background: linear-gradient(90deg, #0a4ae6 0%, #3272d7 100%);
  border-radius: 5px;
  transform: translateX(0);
  width: 38px;
  height: 38px;
  transition: left 1.5s cubic-bezier(0.14, 1.00, 0.34, 1.00);
  &__content{
    position: relative;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    background: linear-gradient(63deg, #3272d7 0%, #0a4ae6 100%);
    border-radius: 5px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0 19px 0;
    z-index: 100;
  }
  &:after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    height: 45px;
    background: linear-gradient(90deg, #0a4ae6 0%, #3272d7 100%);
    transform: translateX(-50%);
    width: 3px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    z-index: 100;
  }
}
.fire{
  &__left{
    right: 100%;
    --rotate: -90deg;
    --center: 40%
  }
  &__right{
    left: 100%;
    --rotate: 90deg;
    --center: -40%
  }
  position: absolute;
  opacity: 0;
  top: 50%;
  z-index: 1;
  transform: translate(var(--center), -50%) rotate(var(--rotate));
  transition: opacity .4s ease .6s, transform 1s;
  &__active{
    opacity: 1;
    transition: opacity .05s, translate 0.2s;
    transform: translateY(-50%) rotate(var(--rotate));
  }
}


