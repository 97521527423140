.cover{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 430px;
  z-index: -1;
  opacity: 0.5;
}
.title{
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
}
.subtitle{
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #5a6076;
  margin-bottom: 30px;

}
.roadmap{
  margin-bottom: 50px;
  width: 100%;
  height: auto;
}

.share{
  &>*:not(:last-child){
    margin-bottom: 10px;
  }
  &>input{
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    width: 100%;
    border: 2px solid #2c303e;
    border-radius: 10px;
    background: #131928;
    outline: none;
    padding: 16px;
    &::placeholder{
      color: #6d7286;
    }
  }
}
.share__title{
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #fff;
  width: 100%;
  margin-bottom: 15px !important;
}

.btn{
  height: 54px;
  width: 100%;
  border-radius: 50px;
  background: linear-gradient(90deg, #0a4ae6 0%, #3272d7 100%)
}

.btn__content{
  background: linear-gradient(63deg, #3272d7 0%, #0a4ae6 100%);
  border-radius: 50px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #fff;
  padding: 17px 0 19px 0;
}


.withdrawal{
  background: #2b2d3a;
  border-radius: 15px;
  width: 100%;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.withdrawalTitle{
  font-size: 18px;
  text-align: center;
  color: #fff;
}
.withdrawalButton{
  width: 40px;
  height: 40px;
  background: #171926;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}