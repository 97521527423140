.block {
  position: absolute;
  width: 100%;
  height: 100%;
}
.coins {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%) scale(4);
  pointer-events: none;
}
.stars {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) scale(3);
  pointer-events: none;
}
