.block{
  width: 100%;
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
  background-color: #1C1F2E;
  height: 89px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 27px;
  z-index: 20 ;
  border-top: 2px solid #282B3D;
  &>*:nth-child(3){
    &>svg>path{
      fill: #fff !important;
    }
  }
}

.play{
  box-shadow: 0 0 20px 0 rgba(44, 109, 251, 0.5);
  border: 1px solid #4987f4;
  border-radius: 12px;
  width: 59px;
  height: 59px;
  background: #2c6dfb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  font-weight: 400;
  width: 40px;
  font-size: 10px;
  color: #6A6F83;
  &>svg>path{
    fill: #6A6F83;
    transition: all 0.5s ease 0s;
  }
  &>div{
    color: inherit;
  }
}

.linkActive{
  color: #fff;
  &>svg>path{
    fill: #fff;
    transition: all 0.5s ease 0s;
  }
}

.active{
  fill: #2c6dfb !important;
  animation: activeTasks 1.5s infinite;
}

@keyframes activeTasks {
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
