.block{
  padding: 0 20px;
}
.header{
  margin-top: 20px;
  font-weight: 500;
  font-size: 26px;
  color: #3d3f4c;
  margin-bottom: 20px;
}
.form{
  backdrop-filter: blur(70px);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 20px;

  &>*:not(:last-child){
    margin-bottom: 10px;
  }
}
.title{
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin-bottom: 20px !important;
}
.input{
  background: #171926;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 11px 20px 13px 20px;
  width: 100%;
  outline: none;
  color: #fff;
  &::placeholder{
    color: #3F414E;
  }
}

.btn{
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #fff;
  width: 100%;
  height: 40px;
  background: linear-gradient(63deg, #3272d7 0%, #0a4ae6 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
}