.cover {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 430px;
  z-index: 1;
  opacity: 0.5;
  user-select: none;
}

.body {
  overflow: hidden;
  position: relative;
  background: #1b1c2e;
  border: 2px solid #282b3d;
  border-radius: 20px;
  padding: 0 17px 25px 17px;
}
.changeBtn {
  position: absolute;
  top: 10px;
  right: 20px;
  width: max-content;
  height: max-content;
  z-index: 1;
  background-color: transparent;
}

.stars {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  color: #5a6076;
  top: -2px;
  left: 0;
  z-index: 1;
  background-color: transparent;
  & > div {
    padding: 21px 0 0 24px;
  }
}

.amount {
  font-weight: 500;
  font-size: 26px;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.coin {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 50px;
}
.coinBody {
  position: relative;
}
.click {
  pointer-events: none;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid rgba(255, 243, 65, 0.3);
  animation: 0.2s clickCoin;
}

.number {
  position: absolute;
  text-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.25);
  font-weight: 500;
  font-size: 16px;
  left: var(--left);
  opacity: 0;
  color: #fff;
  z-index: 101;
  animation: 2s number;
}

@keyframes clickCoin {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  80% {
    transform: translate(-50%, -50%) scale(1.7);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes number {
  0% {
    bottom: 0;
    left: var(--left);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: var(--top);
  }
}

.amount {
}

.progressBar {
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBarValue {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #6a6f83;
  margin-bottom: 10px;
}

.progressBarValueActive {
  color: #fff;
  text-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.25);
}

.progressBarScale {
  background: #131928;
  border: 1px solid #2c303e;
  border-radius: 160px;
  width: 145px;
  height: 6px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #6a6f83;
    border-radius: 160px;
    width: calc(var(--procent) * 1%);
    height: 100%;
    transition: all 0.1s;
  }
}

.coinPress {
  position: relative;
  z-index: 100;
  transform: scale(0.9);
  transition: 0.2s;
}
.coinUnpress {
  transform: scale(1);
  position: relative;
  z-index: 100;
}

.image {
  display: flex;
  justify-content: center;
}

.title {
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  transform: translateY(-22px);
  color: #fff;
  text-transform: uppercase;
  & > span {
    color: #2c6dfb;
  }
}
.info {
  padding: 13px 29px 13px 22px;
  height: 74px;
  position: relative;
  border: 1px solid #2c303e;
  border-radius: 10px;
  background: #131928;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & > img {
    position: absolute;
    top: -15px;
    left: 22px;
  }
}

.infoText {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  max-width: 140px;
  margin-left: 94px;
}

.subTitle {
  font-weight: 400;
  font-size: 14px;
  color: #6a6f83;
  margin-bottom: 10px;
}

.text {
  font-weight: 400;
  font-size: 16px;
  color: #6a6f83;
}
