.wrapper{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100dvw;
  height: 100dvh;
  max-width: 430px;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
}
.wrapperActive{
  opacity: 1;
  pointer-events: all;
}
.body{
  position: absolute;
  bottom: 88px;
  left: 20px;
  width: calc(100% - 40px);
  background: #171926;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: 20px;
}
.cross{
  position: absolute;
  top: 20px;
  right: 20px;
}
.title{
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  margin-bottom: 20px;
}
.row{
  display: flex;
  gap: 8px;
}
.delete{
  flex: 1 1 50%;
  font-weight: 500;
  font-size: 14px;
  color: #e60d5b;
  backdrop-filter: blur(70px);
  background: #ffc1ff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  height: 36px;
}
.close{
  flex: 1 1 50%;
  font-weight: 500;
  font-size: 14px;
  color: #0d4de6;
  backdrop-filter: blur(70px);
  background: #7bbbff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  height: 36px;
}