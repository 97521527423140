.block{
  margin-bottom: 64px;
  z-index: 10;
  position: relative;
  &>*:first-child{
    width: 100%;
    max-width: 100%;
    display: flex;
    border: 2px solid #2c303e;
    border-radius: 10px;
    background: #131928;
    height: 53px;
    padding: 0 20px 0 15px;
    gap: 15px;
    margin-bottom: 11px;
    align-items: center;
    &>svg{
      flex: 0 0 33px;
    }
  }
}
.input{
  flex: 1 1 100%;
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  &::placeholder{
    color: #6d7286;
  }
}
.cross{
  flex: 0 0 15px;
  &>svg>path{
    transition: all 0.5s ease 0s;
  }
  @media(hover:hover){
    &:hover>svg>path{
      stroke: red
    }
  }
}

.row{
  display: flex;
  justify-content: space-between;
}

.fastBtn{
  font-weight: 500;
  background: #202538;
  font-size: 12px;
  color: #5a6076;
  padding: 9px 10px;
  border-radius: 7px;
}