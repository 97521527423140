.block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b1c2e;
}
.lottie {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
}
.mixBlend {
  mix-blend-mode: screen;
  width: 180%;
  height: 180%;
}
