.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  z-index: 1;
}
.text {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}
.timer {
  width: 100px;
  height: 6px;
  background-color: #6c6d77;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  span {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #d1d1d6;
  }
}
